<template>
	<div class="goods_detail_wrap">
		<div class="goodsdetail_wrap" v-if="detail">
			<div class="goodsdetail_back" @click="back_list">
				<img class="back_img"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
					alt />
			</div>
			<scroll-y class="scroll_main" @loadMore="load_model" ref="iscroll">
				<!-- 轮播图 -->
				<van-swipe @change="onChange" class="goods_bannerdiv" :autoplay="3000">
					<van-swipe-item class="goods_banner_item" v-for="(item, index) in banner_img_arr" :key="index">
						<img alt="" class="goods_banner_itemicon" v-lazy="item" />
					</van-swipe-item>

					<template #indicator>
						<div class="custom-indicator goods_banner_num">
							{{ current + 1 }}/{{ banner_img_arr.length }}
						</div>
					</template>
				</van-swipe>

				<!-- 商品描述 -->
				<div class="goods_descontainer">
					<div class="goods_content">
						<div class="goods-name">{{ detail.goods_name }}</div>
						<task-tag :task_require="detail.task_require" :task_require_sub="detail.task_require_sub"
							:task_type="detail.task_type" :xhs_prom_type="detail.xhs_prom_type"></task-tag>
						<div class="goods_des_pricecontainer">
							<div class="left-con">
								<div class="goods_yibao">
									<span class="goods_des_title">已报名：</span>{{ detail.bm_num }}人
								</div>
								<div class="avg-price" v-if="detail.avg_price">
									{{ detail.avg_price }}
								</div>
							</div>

							<div v-if="detail.task_type == 1" class="goods_howmuch">
								<span class="goods_des_title">赠品价值：</span>￥{{
			        detail.goods_price / 100
			      }}
							</div>
						</div>
					</div>
				</div>

				<!-- 通告要求 -->
				<div class="goods_request_container">
					<div class="goods_request_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">通告要求</div>
						</div>
						<div class="goods_request_detail">
							{{ detail.demand ? detail.demand : "无" }}
						</div>
					</div>
				</div>


				<!-- 达人要求 -->
				<div class="goods_model_container" v-if="detail.task_require==4">
					<div class="goods_model_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">达人要求</div>
						</div>
						<div class="goods_request_item" v-if="detail.fans_num&&detail.fans_num!=0">
							<div class="goods_request_itemtitle">粉丝要求</div>
							<div class="goods_request_itemdes">≥{{detail.fans_num}}</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">性别要求</div>
							<div class="goods_request_itemdes">{{sexType[detail.sex]}}</div>
						</div>
						<div class="goods_request_item" v-if="detail.tag_ids">
							<div class="goods_request_itemtitle">内容标签</div>
							<div class="goods_request_itemdes">
								<div class="tag-con">
									<div class="tag-item" v-for="(item,index) in field_show_arr" :key="index">{{item}}
									</div>
									<div class="tag-item more-tag" v-if="fieldShow" @click="showAllFieldTag">
										<div>展开</div>
									</div>
								</div>
							</div>
						</div>
						<div class="goods_request_item" v-if="detail.spec_ids">
							<div class="goods_request_itemtitle">特色标签</div>
							<div class="goods_request_itemdes">
								<div class="tag-con">
									<div class="tag-item" v-for="(item,index) in tag_show_arr" :key="index">{{item}}
									</div>
									<div class="tag-item more-tag" v-if="tagShow" @click="showAllSpecTag">
										<div>展开</div>
									</div>
								</div>
							</div>
						</div>
						<div class="goods_request_item" v-if="detail.budget&&detail.budget!=0">
							<div class="goods_request_itemtitle">最高预算</div>
							<div class="goods_request_itemdes">{{detail.budget}}元</div>
						</div>
					</div>
				</div>

				<!-- 模特要求 -->
				<div class="goods_model_container" v-else>
					<div class="goods_model_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">模特要求</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">年龄要求</div>
							<div v-if="detail.min_age == 0 && detail.max_age == 0" class="goods_request_itemdes">不限
							</div>
							<div v-else class="goods_request_itemdes">最小{{ detail.min_age }}岁，最大{{ detail.max_age }}岁
							</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">模特类型</div>
							<div class="goods_request_itemdes">{{mdTypes[detail.mote_type]}}</div>
						</div>
						<div class="goods_request_item" v-if="detail.budget&&detail.budget!=0">
							<div class="goods_request_itemtitle">最高预算</div>
							<div class="goods_request_itemdes">{{detail.budget}}元</div>
						</div>
					</div>
				</div>

				<!-- 拍摄要求 -->
				<div class="goods_model_container">
					<div class="goods_model_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">拍摄要求</div>
						</div>
						<div class="goods_request_item" v-if="detail.task_require==4">
							<div class="goods_request_itemtitle">拍摄需求</div>
							<div class="goods_request_itemdes">{{promType[detail.xhs_prom_type]}}</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">露脸要求</div>
							<div class="goods_request_itemdes">{{ detail.show_face == 1 ? "必须" : "随意" }}</div>
						</div>
						<div class="goods_request_item"
							v-if="detail.photo_num&&(detail.xhs_prom_type==1||detail.task_require==1||detail.task_require==3)">
							<div class="goods_request_itemtitle">照片数量</div>
							<div class="goods_request_itemdes">不少于{{ detail.photo_num }}张</div>
						</div>
						<div class="goods_request_item"
							v-if="(detail.video_check==1&&detail.video_len)||(detail.task_require==4&&detail.xhs_prom_type==2&&detail.video_len)">
							<div class="goods_request_itemtitle">视频要求</div>
							<div class="goods_request_itemdes">不低于{{detail.video_len}}秒</div>
						</div>
						<div class="goods_request_item" v-if="detail.task_require!=4">
							<div class="goods_request_itemtitle">拍摄要求</div>
							<div class="goods_request_itemdes">{{ detail.photo_demand ? detail.photo_demand : "无要求" }}
							</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">
								{{(detail.task_require==2||detail.task_require==4)?'交付时间':'出图时间'}}</div>
							<div class="goods_request_itemdes">收到货<font
									style="color: #FF6685;font-weight: 700;padding: 0 3px;">{{detail.commit_over_time}}天
								</font>内</div>
						</div>
						<div class="goods_request_item" v-if="detail.task_require==4&&detail.jbyq">
							<div class="goods_request_itemtitle">脚本来源</div>
							<div class="goods_request_itemdes">{{detail.jbyq==1?'商家提供':'达人编写'}}</div>
						</div>
						<div class="goods_request_item" v-if="detail.task_require==4">
							<div class="goods_request_itemtitle">留存时间</div>
							<div class="goods_request_itemdes">
								<div style="display: flex;align-items: center;">
									<span
										style="font-weight: 700;color: #FF6685;margin-right: 8px;">{{detail.xhs_forever_save==1?'永久':(detail.xhs_save_time+'个月')}}</span>
									<div style="position: relative;">
										<i class="iconfont icon-question-circle" @click="showTip"></i>
										<template v-if="saveTimeTip">
											<div class="sanjiao"></div>
											<div class="tip-pop" @click="showTip">小红书笔记发布后，留存时间内不可删除，否则以违规处理</div>
										</template>
									</div>
								</div>
							</div>
						</div>

						<div class="goods_request_titlediv" v-if="detail.photo_sample && detail.photo_sample[0]"
							style="margin-top: 30px">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">参考图</div>
						</div>
						<div v-if="detail.photo_sample && detail.photo_sample[0]" class="goods_model_imgcontainer">
							<div class="goods_model_imgdiv" :key="index" v-for="(item, index) in detail.photo_sample">
								<img alt="" @click="show_image(index)" class="goods_model_img" v-lazy="item" />
							</div>
						</div>

						<div class="goods_request_titlediv" v-if="detail.video_sample && detail.video_sample[0]"
							style="margin-top: 30px">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">参考视频</div>
						</div>
						<div v-if="detail.video_sample && detail.video_sample[0]" class="goods_model_imgcontainer">
							<div class="goods_model_videodiv" :key="index" v-for="(item, index) in detail.video_sample"
								@click="show_video(item)">
								<video class="goods_model_video" :src="item"></video>
								<img class="play-icon" src="../../public/static/play.png" />
							</div>
						</div>
					</div>
				</div>

				<!-- 通告信息 -->
				<div class="goods_request_container">
					<div class="goods_request_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">通告信息</div>
						</div>
						<!-- 小红书代发 -->
						<template v-if="detail.task_require==4&&detail.xhs_prom_type==3">
							<div class="goods_request_item">
								<div class="goods_request_itemtitle">联系方式</div>
								<div class="goods_request_itemdes">{{ detail.connect_type == 1 ? "微信联系" : "平台联系" }}
								</div>
							</div>
						</template>
						<template v-else>
							<div class="goods_request_item" v-if="detail.task_type == 2">
								<div class="goods_request_itemtitle">商品重量</div>
								<div class="goods_request_itemdes">{{ detail.goods_weight }}kg</div>
							</div>
							<div class="goods_request_item">
								<div class="goods_request_itemtitle">联系方式</div>
								<div class="goods_request_itemdes">{{ detail.connect_type == 1 ? "微信联系" : "平台联系" }}
								</div>
							</div>
							<div class="goods_request_item">
								<div class="goods_request_itemtitle">不发地区</div>
								<div class="goods_request_itemdes">{{ detail.no_cityArr ? detail.no_cityArr : "无" }}
								</div>
							</div>
							<div class="goods_request_item" v-if="detail.task_type == 2">
								<div class="goods_request_itemtitle">寄回地址</div>
								<div class="goods_request_itemdes">{{ detail.return_address }}</div>
							</div>
							<div class="goods_request_item"
								v-if="detail.publish_channel && detail.publish_channel != 0">
								<div class="goods_request_itemtitle">发布渠道</div>
								<div class="goods_request_itemdes">
									<div class="channel-type-con">
										<img class="channel-icon" :src="channels[detail.publish_channel].icon" />
										<div>{{ channels[detail.publish_channel].name }}</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>

				<div class="goods_course_container">
					<div class="goods_course_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">通告流程</div>
						</div>
						<img v-if="detail.task_type == 1" class="goods_course_img"
							:src="detail.task_require==4?'https://img.jimeimadou.com/common/images/rbook-zp.png':'https://img.jimeimadou.com/common/images/liucheng_zengpai.png'" />
						<img v-if="detail.task_type == 2" class="goods_course_img"
							:src="detail.task_require==4?'https://img.jimeimadou.com/common/images/rbook-jp.png':'https://img.jimeimadou.com/common/images/liucheng_jipai.png'" />
					</div>
				</div>
			</scroll-y>

			<div class="next-btn" v-if="tasklist_params" @click="nextTask">
				<div class="next-txt">下一个</div>
				<img class="next-icon" src="../../public/static/down.png" alt="" />
			</div>

			<!-- <div class="bottom-container">
				<div class="goods_rz_container" v-if="user_is_login == 0">
					<div class="goods_rz_titlediv">
						<img alt="" class="goods_rz_icon"
							src="https://img.jimeimadou.com/common/resource/normal_icon/normal_gth_icon.png" />
						<div class="goods_rz_title">请先登录</div>
					</div>
					<div class="goods_rz_btndiv" @click="to_login">去登录</div>
				</div>

				<template v-else-if="user_is_login == 1">
					<div class="goods_rz_container"
						v-if="detail.task_require != 4 && mt_auth_status != 2 && mt_auth_status != 5">
						<div class="goods_rz_titleview">
							<img alt="" class="goods_rz_icon"
								src="https://img.jimeimadou.com/common/resource/normal_icon/normal_gth_icon.png" />
							<div class="goods_rz_title" v-if="mt_auth_status == 3">您提交的模特认证二审未通过</div>
							<div class="goods_rz_title" v-else-if="mt_auth_status == 10">您提交的模特认证初审未通过</div>
							<div class="goods_rz_title" v-else-if="mt_auth_status == 8">报名通告前，您需要先完成实名认证</div>
							<div class="goods_rz_title" v-else-if="mt_auth_status == 7">您提交的模特认证不符合要求</div>
							<div class="goods_rz_title" v-else>报名通告前，您需要先完成模特认证</div>
						</div>
						<div class="goods_rz_btndiv" @click="to_authen">
							{{mt_auth_status == 3 || mt_auth_status == 10 ? '重新认证' : mt_auth_status == 7 ? '去推广' : mt_auth_status == 8 ? '去实名' : '去认证'}}
						</div>
					</div>

					<div class="goods_rz_container" v-else-if="has_dispute == 1">
						<div class="goods_rz_titleview">
							<img alt="" class="goods_rz_icon"
								src="https://img.jimeimadou.com/common/resource/normal_icon/normal_gth_icon.png" />
							<div class="goods_rz_title">您当前存在投诉通告，暂时无法申请其他通告</div>
						</div>
						<div class="goods_rz_btndiv" @click="onDisputeDetail">查看</div>
					</div>

					<div class="goods_rz_container" v-else-if="detail.task_require == 4 && xhs_auth_state != 2">
						<div class="goods_rz_titleview">
							<img alt="" class="goods_rz_icon"
								src="https://img.jimeimadou.com/common/resource/normal_icon/normal_gth_icon.png" />
							<div class="goods_rz_title" v-if="xhs_auth_state == 0">您当前未添加小红书名片</div>
							<div class="goods_rz_title" v-else-if="xhs_auth_state == 1">您添加的小红书名片资料正在审核中</div>
							<div class="goods_rz_title" v-else-if="xhs_auth_state == 3">您添加的小红书名片资料未通过审核</div>
						</div>
						<div class="goods_rz_btndiv" v-if="xhs_auth_state == 0 ||xhs_auth_state == 3"
							@click="onXhsDetail">{{xhs_auth_state == 0 ? '去添加' : '重新添加'}}</div>
					</div>

					<div class="goods_rz_container" v-else-if="detail.task_require == 4 && xhs_verify == 0">
						<div class="goods_rz_titleview">
							<img alt="" class="goods_rz_icon"
								src="https://img.jimeimadou.com/common/resource/normal_icon/normal_gth_icon.png" />
							<div class="goods_rz_title">您当前小红书未完成实名认证</div>
						</div>
						<div class="goods_rz_btndiv" @click="onXhsAuth">去实名</div>
					</div>
				</template>
			</div> -->

			<!-- <div class="goods_share_container">
				<div class="goods_apply">
					<div v-if="user_is_login == 1 && detail.has_baoming == 1" style="background-color:#DCDFE6"
						class="goods_apply_btn">已报名</div>
					<div v-else :style="{backgroundColor: show_btn ? '#1A1F29' : '#DCDFE6'}" class="goods_apply_btn"
						@click="onApply">报名</div>
				</div>
			</div> -->
			
			<div class="goods_share_container">
				<div class="goods_apply">
					<div class="goods_apply_btn" @click="toWeApp">报名</div>
				</div>
			</div>

			<van-popup class="vant_popup_box" v-model="showVisible">
				<div class="popup_wrapper">
					<div class="model-title" v-if="modelName === 'tipModel' || modelName === 'tipModel2'">
						<template v-if="modelName === 'tipModel'">
							温馨提示
						</template>
						<template v-else-if="modelName === 'tipModel2'">
							商品寄拍tips
						</template>
					</div>
					<div :class="['model_content', modelName === 'tipModel2' ? 'text-left' : '']">
						<template v-if="modelName === 'authModel'">
							{{modelContent}}
						</template>
						<template v-else-if="modelName === 'channelModel'">
							您当前未完成{{channels[detail.publish_channel].name}}渠道认证，认证通过后才可报名此通告
						</template>
						<template v-else-if="modelName === 'tipModel'">
							{{tipText}}
						</template>
						<template v-else-if="modelName === 'tipModel2'">
							<div class="tip-item">1.接单后请跟商家沟通好物品拍摄要求。贵重产品快递开箱和寄回产品保存好视频图片记录。</div>
							<div class="tip-item">
								2.衣物鞋子如需外景请自备换装，到地点后换装拍摄，避免损坏弄脏，有吊牌的请事先和商家沟通是否可拆，影响二次销售需要自购赔偿。（收货第一时间检查商品，如发现问题请第一时间提出，并拍照留证据。）
							</div>
						</template>
					</div>
					<div class="flex justify-between model-btn-con">
						<template v-if="modelName === 'authModel'">
							<div class="model-btn-item-l" @click="hideModel">取消</div>
							<div class="model-btn-item-r" @click="toCreateAlbum">{{isCreate?'去创建':'去查看'}}</div>
						</template>
						<template v-else-if="modelName === 'channelModel'">
							<div class="model-btn-item-l" @click="hideModel">取消</div>
							<div class="model-btn-item-r" @click="toAuth">去认证</div>
						</template>
						<template v-else-if="modelName === 'tipModel'">
							<div class="model-btn-item-r" @click="hideModel">我知道了</div>
						</template>
						<template v-else-if="modelName === 'tipModel2'">
							<div class="model-btn-item-r" @click="toApply">我知道了</div>
						</template>
					</div>
				</div>
			</van-popup>
		</div>
		<van-loading color="#1989fa" type="spinner" v-else />
	</div>
</template>

<script>
	var sign_module = require("../../public/js/sign");
	import axios from "axios";
	import ScrollY from "../components/app-scroll-y";
	import VanSwipe from "vant/lib/swipe";
	import VanSwipeItem from "vant/lib/swipe-item";
	import "vant/lib/swipe/style";
	import "vant/lib/swipe-item/style";
	import taskTag from "../components/task-tag";
	import qs from "qs";
	import VanImagePre from "vant/lib/image-preview";
	import "vant/lib/image-preview/style";
	export default {
		components: {
			ScrollY,
			VanSwipe,
			VanSwipeItem,
			taskTag
		},
		data() {
			return {
				id: "",
				current: 0,
				banner_img_arr: null,
				detail: null,
				task_ids: [],
				tasklist_params: null,
				current_task: -1,
				scrollTop: 0,
				channels: this.channels,
				sexType: this.sexType,
				fieldList: [],
				fieldShow: false,
				field_show_arr: [],
				tagList: [],
				tagShow: false,
				tag_show_arr: [],
				tag_maxshow_num: 5, //最多展示几个标签，其余隐藏
				mdTypes: this.mdTypes,
				promType: this.promType,
				saveTimeTip: false,
				md_type: 0,
				user_is_login: 0,
				mt_auth_status: 0,
				has_dispute: 0,
				xhs_auth_state: 0,
				xhs_verify: 0,
				show_btn: false,
				moduleName: '',
				province_id: '',
				daren_status: 0,
				zc_num: 0,
				zt_num: 0,
				zc_total_num: 0,
				zt_total_num: 0,
				status: 0,
				mote_roles: '',
				mote_roles_arr: [],
				showVisible: false,
				modelName: '',
				modelContent: '',
				tipText: '',
				isCreate: false,
				xhs_nick: '',
				xhs_head_url: ''
			};
		},
		computed: {
			module() {
				return this.moduleName
			}
		},
		created() {},
		activated() {
			this.showVisible = false;
		},
		deactivated() {
			//this.detail = null;
			this.showVisible = false;
		},
		methods: {
			//展示所有内容标签
			showAllFieldTag() {
				this.field_show_arr = this.fieldList;
				this.fieldShow = false
			},
			//展示所有特色标签
			showAllSpecTag() {
				this.tag_show_arr = this.tagList;
				this.tagShow = false
			},
			showTip() {
				this.saveTimeTip = !this.saveTimeTip
			},
			load_model() {
				console.log("触底了")
			},
			nextTask() {
				this.current_task++;
				if (!this.task_ids[this.current_task]) {
					//没有下一个通告时请求下一页通告列表数据
					this.tasklist_params.page++;
					this.getTaskList();
					return;
				}
				this.id = this.task_ids[this.current_task];
				this.getGoodsDetail();
			},
			getTaskList() {
				var params = sign_module.signParams(this.tasklist_params);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios.post(
						process.env.VUE_APP_URL + "/mobi/task/list",
						qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							let result = JSON.parse(res.data.data);
							// console.log("下一页通告数据", result);
							if (result.list.length == 0) {
								//没有下一页时从头开始
								this.current_task = 0;
							} else {
								this.task_ids = this.task_ids.concat(result.id_arr);
							}
							this.id = this.task_ids[this.current_task];
							// console.log(task_ids,this.id)
							this.getGoodsDetail();
						}
					});
			},
			getGoodsDetail() {
				var initparams = {
					task_id: this.id,
					access_token: localStorage.getItem("access_token")
				};
				var params = sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios.post(
						process.env.VUE_APP_URL + "/mobi/task/detail",
						qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						var data = JSON.parse(res.data.data);
						//console.log(data);
						data.goods_pic = data.goods_pic && data.goods_pic.split(",") || [];
						data.mote_type = data.mote_type ? parseInt(data.mote_type) : 0;
						if (data.tag_names) {
							let fieldList = data.tag_names.split(",");
							this.fieldList = fieldList;
							this.field_show_arr = fieldList.length > this.tag_maxshow_num ? fieldList.slice(0, this
								.tag_maxshow_num) : fieldList;
							this.fieldShow = fieldList.length > this.tag_maxshow_num
						}
						if (data.spec_names) {
							let tagList = data.spec_names.split(",");
							this.tagList = tagList;
							this.tag_show_arr = tagList.length > this.tag_maxshow_num ? tagList.slice(0, this
								.tag_maxshow_num) : tagList;
							this.tagShow = tagList.length > this.tag_maxshow_num
						}
						data.photo_sample = data.photo_sample && data.photo_sample.split(",") || [];
						data.video_sample = data.video_sample && data.video_sample.split(";") || [];
						this.banner_img_arr = data.goods_pic;
						this.detail = data;
						document.documentElement.scrollTop = 0;
						this.checkShowBtn();

						this.$nextTick(() => {
							this.$refs.iscroll.scrollToTop();
						})
					});
			},
			checkShowBtn() {
				let initparams = {
					access_token: localStorage.getItem("access_token")
				};
				let params = sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios.post(
					process.env.VUE_APP_URL + "/mobi/center/index",
					qs.stringify(params), {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}
				).then((res) => {
					if (res.data.status == 'y') {
						//console.log("返回的用户信息", JSON.parse(res.data.data));
						let result = JSON.parse(res.data.data);

						this.md_type = parseInt(result.md_type);
						this.province_id = result.province_id;
						this.mt_auth_status = parseInt(result.mt_auth_status);
						this.has_dispute = parseInt(result.has_dispute);
						this.daren_status = parseInt(result.daren_status);
						this.zc_num = result.zc_num;
						this.zt_num = result.zt_num;
						this.zc_total_num = result.zc_total_num;
						this.zt_total_num = result.zt_total_num;
						this.status = parseInt(result.status);
						this.mote_roles = result.mote_roles;
						let mote_roles_arr = [];
						if (result.mote_roles.length) {
							mote_roles_arr = result.mote_roles.map(item => parseInt(item.md_type))
						}
						this.mote_roles_arr = mote_roles_arr;
						this.xhs_verify = parseInt(result.xhs_verify);
						this.xhs_auth_state = parseInt(result.xhs_auth_state);
						this.xhs_nick = result.xhs_nick;
						this.xhs_head_url = result.xhs_head_url;
						let detail_finish = result.detail_finish;

						//非小红书通告报名需满足模特认证通过（被限制也算）+ 完善信息 + 没有纠纷订单 + 账号正常没有封号
						let mjx_task_bm_status = this.detail.task_require != 4 && (this.mt_auth_status == 2 || this
							.mt_auth_status == 5) && detail_finish && this.has_dispute != 1 && this.status == 1;
						//小红书通告报名需满足 已添加名片 + 名片实名 + 没有纠纷订单 + 账号正常没有封号
						let rbook_task_bm_status = this.detail.task_require == 4 && this.xhs_verify == 1 && this
							.xhs_auth_state == 2 && this.has_dispute != 1 && this.status == 1;
						this.show_btn = mjx_task_bm_status || rbook_task_bm_status;
						this.user_is_login = 1;
					} else {
						this.user_is_login = 0;
					}
				})
			},
			back_list() {
				this.$router.back(-1);
			},
			to_login() {
				this.$router.push({
					name: "login"
				});
			},
			toCreateAlbum() {
				this.$router.push({
					name: "zhongcaoAlbum"
				});
			},
			toAuth() {
				this.$router.push({
					name: "channelAuth",
				});
			},
			to_authen() {
				if (this.mt_auth_status == 4 || this.mt_auth_status == 9) {
					//待审核
					this.$router.push({
						name: "waitAuth",
						query: {
							title: "mtAuth"
						}
					});
				} else if (this.mt_auth_status == 1||this.mt_auth_status == 10) {
					//初审未认证或初审拒绝
					this.$router.push({
						name: "photoAuth"
					});
				} else if (this.mt_auth_status == 3||this.mt_auth_status == 8) {
					//二审拒绝
					this.$router.push({
						name: "infoAuth"
					});
				} else if (this.mt_auth_status == 7) {
					//不符合要求
					console.log("去推广")
				}
			},
			onDisputeDetail() {
				this.$router.push({
					name: 'order'
				})
			},
			onXhsDetail(){
				this.$router.push({
					name: 'editCard'
				})
			},
			onXhsAuth(){
				this.$router.push({
					name: 'xhsRename'
				})
			},
			toWeApp(){
				this.$router.push({
					name: 'shareCode'
				})
			},
			onApply() {
				//模特未完成模特认证或者账号存在投诉通告
				if (!this.show_btn || JSON.stringify(this.detail) == "{}") {
					return
				}
				//status==2被封号
				if (this.status == 2) {
					this.modelName = 'tipModel';
					this.tipText = '当前帐号已被封号';
					this.showVisible = true;
					return
				}

				if (this.detail.mote_type != 0 && !this.mote_roles_arr.includes(this.detail.mote_type)) {
					let task_mt_type = this.mdTypes[this.detail.mote_type];
					let my_mt_type = this.mdTypes[this.md_type];
					this.tipText =
					`当前通告仅限${task_mt_type}可以报名，您认证的模特类型为${my_mt_type}。您可在亲情相册中创建${task_mt_type}相册，审核通过后即可报名`;
					this.modelName = "tipModel";
					this.showVisible = true;
					return
				}

				//判断地区是否满足报名条件
				if (this.detail.no_cityId && this.province_id) {
					let no_cityId = this.detail.no_cityId.split(",");
					if (no_cityId.includes(this.province_id)) {
						this.tipText = "当前通告不发地区包含您的省份，无法报名该通告";
						this.modelName = "tipModel";
						this.showVisible = true;
						return
					}
				}

				//如果当前通告为种草通告并且有发布渠道，若用户未认证该渠道则提示去认证
				if (this.detail.publish_channel && this.detail.publish_channel != 0 && this.detail.qudao_tips == 1) {
					this.modelName = "channelModel";
					this.showVisible = true;
					return
				}

				// 通告为种草但是种草视频通过数量未达到5
				if (this.detail.task_require == 2 && this.zc_num < 5) {
					let modelContent;
					let isCreate;
					if (this.zc_total_num == 0) {
						modelContent = "当前通告为种草通告，你未创建种草相册，你可在我的-“达人认证”中创建";
						isCreate = true;
					} else {
						modelContent = "当前通告为种草通告，至少上传5个种草视频并审核通过后才可报名";
						isCreate = false;
					}
					this.modelContent = modelContent;
					this.isCreate = isCreate;
					this.modelName = 'authModel';
					this.showVisible = true;
					return
				}

				// 通告为主图详情但是主图详情作品通过数量未达到3
				if (this.detail.task_require == 3 && this.zt_num < 3) {
					let modelContent;
					let isCreate;
					if (this.zt_total_num == 0) {
						modelContent = "当前通告为主图/详情页通告，你未创建主图相册，你可在我的-“达人认证”中创建";
						isCreate = true
					} else {
						modelContent = "当前通告为主图/详情页通告，至少上传3组主图/详情页作品并审核通过后才可报名";
						isCreate = false
					}
					this.modelContent = modelContent;
					this.isCreate = isCreate;
					this.modelName = 'authModel';
					this.showVisible = true;
					return
				}

				if (this.detail.task_require == 4 && this.detail.xhs_bm_status == 1) {
					this.tipText = "您的粉丝数未符合报名条件，无法报名该通告";
					this.modelName = "tipModel";
					this.showVisible = true;
					return
				}
				this.toApply()
			},
			hideModel() {
				this.showVisible = false;
			},
			toApply() {
				this.hideModel();
				this.$router.push({
					name: 'goodsapply',
					query: {
						task_id: this.detail.id,
						price: this.detail.goods_price,
						task_type: this.detail.task_type,
						task_require: this.detail.task_require,
						xhs_prom_type: this.detail.xhs_prom_type,
						budget: this.detail.budget,
						xhs_nick: this.xhs_nick,
						xhs_head_url: this.xhs_head_url,
					}
				})
			},
			onChange(e) {
				this.current = e;
			},
			show_image(index) {
				VanImagePre({
					images: this.detail.photo_sample,
					startPosition: index,
				});
			},
			show_video(album) {
				this.$router.push({
					path: "/main/album",
					query: {
						type: 1,
						album: JSON.stringify({
							videos: album
						}),
					},
				});
			},
		},
		beforeRouteEnter(e, r, next) {
			next(vm => {
				if (r.name !== 'goodsapply') {
					vm.detail = null;
					console.log("vm.$route.query=====",vm.$route.query)
					if (vm.$route.query.ids) {
						vm.task_ids = JSON.parse(vm.$route.query.ids);
					}
					if (vm.$route.query.params) {
						vm.tasklist_params = JSON.parse(vm.$route.query.params);
					}
					if (vm.$route.query.index) {
						vm.current_task = parseInt(vm.$route.query.index);
					}
					vm.id = vm.$route.query.id;
					vm.getGoodsDetail();
				}
			});
		}
	};
</script>

<style lang="scss" scoped>
	.goods_detail_wrap {
		height: 100vh;
		position: relative;

		.van-loading {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-30px) translateY(-30px);

			.van-loading__spinner {
				width: 60px;
				height: 60px;
			}
		}
	}

	.goodsdetail_wrap {
		.scroll_main {
			background: #f2f3f7;
			height: 100vh;
		}

		.goodsdetail_back {
			position: fixed;
			top: 30px;
			left: 30px;
			height: 50px;
			width: 50px;
			border-radius: 50%;
			background: rgba(220, 220, 220, 0.5);
			z-index: 500;
			display: flex;
			justify-content: center;
			align-items: center;

			.back_img {
				height: 80%;
				width: 80%;
				margin-left: 10px;
				opacity: 0.6;
			}
		}
	}

	.goods_bannerdiv {
		width: 750px;
		height: 750px;
		position: relative;
	}

	.goods_banner_itemicon {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.goods_banner_num {
		position: absolute;
		height: 40px;
		background: #000000;
		border-radius: 20px;
		opacity: 0.4;
		padding: 0 20px;
		font-size: 22px;
		color: #ffffff;
		line-height: 40px;
		bottom: 30px;
		right: 30px;
	}

	.goods_descontainer {
		width: 100%;
		padding: 0 18px;
		box-sizing: border-box;
		margin-top: 20px;
	}

	.goods_content {
		background-color: white;
		border-radius: 24px;
		padding: 24px;
		padding-bottom: 15px;
	}

	.goods-name {
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		font-size: 32px;
		line-height: 50px;
	}

	.goods_title_div {
		padding: 0 13px;
		box-sizing: border-box;
		background: #1a1f29;
		color: #fff;
		font-size: 26px;
		margin-right: 10px;
		border-radius: 20px;
		display: inline-block;
		line-height: 40px;
		height: 40px;
	}

	.goods_labelimg {
		width: 18px;
		height: 20px;
		margin-right: 6px;
	}

	.goods_request_item {
		width: 100%;
		display: flex;
		margin-top: 20px;
		/* background-color: red; */
	}

	.goods_request_itemtitle {
		width: 128px;
		font-size: 28px;
		color: #8a8e99;
		line-height: 40px;
		text-align-last: justify;
		flex-shrink: 0;
	}

	.goods_request_itemdes {
		font-size: 28px;
		color: #262a33;
		line-height: 40px;
		width: 100%;
		margin-left: 40px;
		word-break: break-all;
	}

	.tag-con {
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
	}

	.tag-item {
		line-height: 40px;
		font-size: 22px;
		padding: 0 10px;
		border-radius: 6px;
		margin-right: 8px;
		margin-bottom: 8px;
		/* background-color: #FFEBEF;
		color: #FF6685; */
		background-color: #f5f5f5;
		color: #888;
	}

	.more-tag {
		display: flex;
		background-color: #f5f5f5;
		color: #888;
	}

	.tip-pop {
		position: absolute;
		left: calc(100% + 20px);
		bottom: 0;
		width: 320px;
		line-height: 34px;
		font-size: 22px;
		color: #888;
		padding: 12px 15px;
		background-color: #fff;
		box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
	}

	.tip-pop::before {
		content: "";
		width: 15px;
		height: 15px;
		background-color: #fff;
		position: absolute;
		left: 0;
		bottom: 20px;
		transform: translateX(-50%) rotate(45deg);
	}

	.icon-question-circle {
		font-size: 30px;
	}

	.sanjiao {
		width: 15px;
		height: 15px;
		background-color: #fff;
		position: absolute;
		left: calc(100% + 20px);
		bottom: 20px;
		transform: translateX(-50%) rotate(45deg);
		box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
	}

	.plaza_des_lable {
		height: 42px;
		background: #1a1f29;
		border-radius: 21px;
		text-align: center;

		font-size: 22px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 42px;
		margin-right: 10px;
		padding: 0 13px;
	}

	.goods_des_pricecontainer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 26px;
		margin-top: 25px;
		line-height: 40px;

		.left-con {
			display: flex;

			.avg-price {
				background-color: #ffebef;
				color: #ff6685;
				font-size: 24px;
				line-height: 40px;
				height: 40px;
				padding: 0 15px;
				margin-left: 15px;
				border-radius: 20px 20px 20px 0;
			}
		}
	}

	.goods_des_title {
		color: #8a8e99;
	}

	.goods_fen_line {
		color: #d8d8d8;
		font-size: 26px;
		margin: 0 10px;
	}

	.goods_des_pricecdiv {
		display: flex;
		align-items: flex-end;
	}

	.goods_des_price_chart {
		width: 30px;
		height: 42px;
		font-size: 36px;
		color: #ff4e33;
		line-height: 42px;
		margin-bottom: 4px;
	}

	.goods_des_price {
		height: 65px;
		font-size: 54px;
		color: #ff4e33;
		line-height: 65px;
		font-weight: 500;
	}

	.goods_des_price_subtitle {
		height: 34px;
		font-size: 28px;
		color: #8a8e99;
		line-height: 34px;
		margin-left: 10px;
		margin-bottom: 8px;
	}

	.goods_des_numdiv {
		display: flex;
		align-items: flex-end;
	}

	.goods_des_numcontent {
		font-size: 24px;
		line-height: 34px;
		display: flex;
		margin-bottom: 8px;
	}

	.goods_des_numtitle {
		height: 34px;
		color: #8a8e99;
		padding: 0 10px;
		font-size: 28px;
	}

	.goods_des_numsubtitle {
		height: 34px;
		color: #262a33;
	}

	.goods_des_numline {
		width: 2px;
		height: 16px;
		background-color: #d8d8d8;
		margin-bottom: 17px;
	}

	.goods_request_container {
		width: 100%;
		margin-top: 20px;
		padding: 0 18px;
		box-sizing: border-box;
	}

	.goods_request_content {
		width: 100%;
		background-color: white;
		padding: 20px 24px;
		box-sizing: border-box;
		border-radius: 24px;
	}

	.goods_request_titlediv {
		display: flex;
		align-items: center;
	}

	.goods_request_titleline {
		width: 6px;
		height: 32px;
		background: #ff6685;
		border-radius: 3px;
	}

	.goods_request_title {
		margin-left: 6px;
		height: 40px;
		font-size: 32px;
		color: #262a33;
		line-height: 40px;
		font-weight: 600;
	}

	.goods_request_detail {
		font-size: 30px;
		color: #262a33;
		line-height: 40px;
		margin-top: 10px;
		white-space: pre-wrap;
	}

	.goods_course_container {
		width: 100%;
		margin-top: 20px;
		padding: 0 18px 100px;
		box-sizing: border-box;
	}

	.goods_course_content {
		background-color: white;
		padding: 20px 24px 30px 24px;
		box-sizing: border-box;
		border-radius: 24px;
	}

	.goods_course_img {
		width: 100%;
		margin-top: 50px;
	}

	.goods_model_container {
		width: 100%;
		margin-top: 20px;
		padding: 0 18px;
		box-sizing: border-box;
	}

	.goods_model_content {
		background-color: white;
		padding: 20px 24px 20px 24px;
		box-sizing: border-box;
		border-radius: 24px;
	}

	.goods_model_imgcontainer {
		width: 100%;
		height: 168px;
		overflow-x: scroll;
		margin-top: 20px;
		display: flex;
	}

	.goods_model_imgdiv,
	.goods_model_videodiv {
		height: 100%;
		width: 168px;
		overflow: hidden;
		border-radius: 10px;
		margin-left: 10px;
		flex-shrink: 0;
	}

	.goods_model_videodiv {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;

		.goods_model_video {
			background-color: #000;
		}

		.play-icon {
			position: absolute;
			width: 50px;
			height: 50px;
		}
	}

	.goods_model_imgdiv:first-child {
		margin-left: 0;
	}

	.goods_model_img,
	.goods_model_video {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.video_mask {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0);
	}

	.goods_applydiv {
		width: 100%;
		position: fixed;
		bottom: 0;
	}

	.goods_rz_container {
		width: 100%;
		height: 65px;
		background-color: #ffcccc;
		padding: 0 30px 0 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.goods_share_container {
		width: 100%;
		height: 100px;
		background-color: white;
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		bottom: 0;
	}

	.goods_share_div {
		width: 266px;
		flex-shrink: 0;
		height: 100%;
		display: flex;
		align-items: center;
	}

	.goods_apply {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.goods_share_content {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
	}

	.goods_share_btnimg {
		width: 32px;
		height: 32px;
		margin-top: 22px;
	}

	.goods_share_title {
		margin-top: 6px;
		height: 28px;
		font-size: 20px;
		color: #8a8e99;
		line-height: 28px;
	}

	.goods_apply_btn {
		width: 90%;
		height: 70px;
		background-color: #1a1f29;
		border-radius: 70px;
		font-size: 34px;
		color: #ffffff;
		line-height: 70px;
		text-align: center;
	}

	.goods_rz_titlediv {
		display: flex;
		height: 100%;
		align-items: center;
	}

	.goods_rz_titleview {
		display: flex;
		align-items: center;
	}

	.goods_rz_icon {
		width: 30px;
		height: 30px;
		display: block;
	}

	.goods_rz_title,
	.goods_rz_btnview {
		font-size: 28px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #262a33;
		margin-left: 15px;
	}

	.goods_rz_btndiv {
		width: 120px;
		height: 40px;
		border-radius: 20px;
		border: 2px solid #1a1f29;
		font-size: 24px;
		color: #1a1f29;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.weui-gallery__opr {
		display: none !important;
	}

	.back {
		position: fixed;
		top: 60px;
		left: 30px;
		z-index: 4;
	}

	.next-btn {
		position: fixed;
		right: 40px;
		bottom: 240px;
		width: 90px;
		height: 90px;
		text-align: center;
		line-height: 24px;
		font-size: 20px;
		border-radius: 50%;
		box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
		background-color: #fff;
		color: #888;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		.next-txt {
			font-size: 18px;
			padding-top: 8px;
		}

		.next-icon {
			width: 25px;
			height: 25px;
			display: block;
		}
	}

	.bottom-container {
		position: fixed;
		bottom: 100px;
		width: 100%;
	}

	.channel-type-con {
		display: flex;
		align-items: center;

		.channel-icon {
			width: 30px;
			height: 30px;
			margin-right: 5px;
			vertical-align: middle;
		}
	}

	::v-deep .van-overlay {
		background-color: rgba(0, 0, 0, .4);
	}

	.vant_popup_box {
		border-radius: 16px;

		.popup_wrapper {
			width: 570px;
			padding: 40px 40px;

			.model-title {
				font-weight: 600;
				font-size: 34px;
				color: #000;
				text-align: center;
			}

			.model_content {
				margin: 40px 0;
				color: #000;
				font-size: 30px;
				line-height: 40px;
			}

			.tip-item {
				padding: 2px 0;
			}

			.model-btn-con {
				display: flex;
			}

			.model-btn-con>div {
				width: 220px;
				font-size: 30px;
				line-height: 65px;
				border-radius: 30px;
				border: 1px solid #ddd;
				text-align: center;
				margin: 0 auto;
			}

			.model-btn-item-r {
				background-color: #000000;
				color: #fff;
				border-color: #000000 !important;
			}
		}
	}
</style>
