<template>
	<div class="container">
		<div class="goodsapply_title">
			<div class="back_img">
				<img class="back_img"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
					@click="back_list" alt />
			</div>
			报名
		</div>
		<div class="goodsapply_content">
			<div class="goodsapply_pricecontainer">
				<div class="goodsapply_typediv" v-if="xhs_prom_type != 3">
					<div class="goodsapply_typetitle">{{task_type == 1 ? "商品赠送" : "商品寄拍"}}</div>
				</div>
				<div class="goodsapply_bottomtitle">
					<template v-if="xhs_prom_type == 3">
						<div><span style="color: #FF704D;">tips：</span>报价可根据商家任务来自主填写</div>
					</template>
					<template v-else>
						<div v-if="task_type == 1"><span style="color: #FF704D;">tips：</span>赠拍商品无佣金，但您也可以要求另加佣金</div>
						<div v-if="task_type == 2">
							<span style="color: #FF704D;">tips：</span>报价可根据商家任务来自主填写，<span
								style="color: #FF704D;">包含寄回运费</span>
							<div v-if="task_require == 1">温馨提示：30-60元接单更快哦~</div>
							<div v-if="task_require == 2">温馨提示：50-200元接单更快哦~</div>
						</div>
					</template>
				</div>
				<div class="goodsapply_inputcontainer">
					<div class="goodsapply_input_title">
						<span>佣金报价</span>
					</div>
					<div class="goodsapply_input_content">
						<van-field class="goodsapply_input" v-model="mt_price" placeholder="请输入佣金报价" />
						<div class="goodsapply_input_subtitle">元</div>
					</div>
					<div class="goodsapply_rember" @click="remeberPrice">
						<van-checkbox v-model="is_remeber_price">记住</van-checkbox>
					</div>
				</div>
			</div>

			<div class="goodsapply_timecontainer">
				<div class="goodsapply_bottomtitle">
					<div><span style="color:#FF704D">tips：</span>承诺收到货后，多长时间内拍摄好图片并提交给商家</div>
					<div>温馨提示：多次违背承诺，将限制报名</div>
				</div>
				<div class="goodsapply_inputcontainer">
					<div class="goodsapply_input_title">
						<span>时间承诺</span>
					</div>
					<div class="goodsapply_input_content">
						<div class="goodsapply_input">
							<van-field readonly clickable :value="picker_time" @click="showPicker = true" />
							<van-popup v-model="showPicker" round position="bottom">
								<van-picker show-toolbar item-height="60" :columns="picker_time_range" @cancel="showPicker = false"
									@confirm="onConfirm" />
							</van-popup>
						</div>
						<div class="goodsapply_input_subtitle">小时</div>
					</div>
					<div class="goodsapply_rember" @click="remeberDate">
						<van-checkbox v-model="is_remeber_date">记住</van-checkbox>
					</div>
				</div>

			</div>

			<div class="red-book" v-if="task_require == 4 && showXhs">
				<div class="red-tag">小红书</div>
				<div class="red-subtitle">通告模特</div>
				<img class="rbook-avatar" :src="xhs_head_url" />
				<div class="rbook-nick">{{xhs_nick}}</div>
				<span class="cuIcon-right"></span>
			</div>

			<div class="tip-container" v-if="task_type == 2">
				<van-icon name="question-o" />
				<div class="flex-sub">商家审核通过后，请在48小时内寄回商品，否则将被限制报名!</div>
			</div>

			<div class="goodsapply_btn" @click="applyTask">立即报名</div>
		</div>

		<van-popup class="vant_popup_box" v-model="showVisible">
			<div class="popup_wrapper">
				<div class="model-title">
					温馨提示
				</div>
				<div class="model_content">
					您的寄拍佣金报价为<span
						style="color: #FF704D;font-weight: 600;padding: 0 2px;">{{mt_price}}</span>元，其中包含寄回运费，是否确认报名？
				</div>
				<div class="flex justify-between model-btn-con">
					<div class="model-btn-item-l" @click="hideModel">取消</div>
					<div class="model-btn-item-r" @click="sureApply">确认报名</div>
				</div>
			</div>
		</van-popup>
	</div>

</template>

<script>
	import axios from "axios";
	import qs from "qs";

	export default {
		name: "GoodsApply",
		data() {
			return {
				picker_time_range: [24, 48, 72, 96, 120],
				picker_time: 72,
				mt_price: '',
				task_id: '',
				goods_price: '',
				task_type: '',
				task_require: '',
				xhs_prom_type: 0,
				is_remeber_date: false,
				is_remeber_price: false,
				xhs_nick: "",
				xhs_head_url: "",
				modelName: "",
				showPicker: false,
				budget: '',
				showVisible: false,
				showXhs: false
			}
		},
		methods: {
			back_list() {
				//this.$router.push({name: 'plaza'});
				this.$router.back(-1);
			},
			//选择承诺时间
			onConfirm(value) {
				this.picker_time = value;
				this.showPicker = false;
			},
			//记住承诺时间
			remeberDate() {
				this.is_remeber_date = !this.is_remeber_date;
			},

			//记住佣金报价
			remeberPrice() {
				this.is_remeber_price = !this.is_remeber_price;
			},

			hideModel() {
				this.showVisible = false;
				this.modelName = "";
			},
			//报名通告
			applyTask() {
				if ((this.task_type == 2 || this.xhs_prom_type == 3) && !this.mt_price) {
					this.$toast('请输入您的佣金报价');
					return
				}
				if (this.budget && parseFloat(this.mt_price) > parseFloat(this.budget)) {
					this.$toast('当前通告最高预算' + parseFloat(this.budget) + '元，请重新修改您的报价');
					return
				}
				if (this.task_type == 1 || this.xhs_prom_type == 3) {
					this.sureApply();
				} else {
					this.modelName = "sureModel";
					this.showVisible = true;
				}
			},
			//确认报名
			sureApply() {
				this.hideModel();
				let initparams = {
					access_token: localStorage.getItem("access_token"),
					task_id: this.task_id,
					promise_time: this.picker_time,
					mt_price: this.mt_price || 0
				};
				//console.log("通告报名请求参数", initparams);
				let params = this.sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios.post(
					process.env.VUE_APP_URL + "/mobi/order/apply",
					qs.stringify(params), {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}
				).then((res) => {
					//console.log("返回的用户信息",res.data);
					if (res.data.status == 'n') {
						this.$toast(res.data.error);
					} else if (res.data.status == 'y') {
						this.$toast('报名成功');
						setTimeout(() => {
							this.$router.push({
								name: 'plaza'
							});
						}, 1000);
					}
				})
			},

			//跳转小红书名片
			// toCardMessage() {
			//   wx.navigateTo({
			//     url: "/packageA/redBookCard/cardMessage/cardMessage"
			//   })
			// },
		},
		activated() {
			//console.log(this.$route.query);
			this.task_id = parseInt(this.$route.query.task_id);
			this.goods_price = parseFloat(this.$route.query.price) / 100;
			this.task_type = parseInt(this.$route.query.task_type);
			this.task_require = parseInt(this.$route.query.task_require);
			this.xhs_prom_type = parseInt(this.$route.query.xhs_prom_type);
			this.xhs_nick = this.$route.query.xhs_nick;
			this.xhs_head_url = this.$route.query.xhs_head_url;
			this.budget = this.$route.query.budget ? parseFloat(this.$route.query.budget) : 0;

			//记住小红书代发通告佣金
			if (this.xhs_prom_type == 3 && this.$ls.get("mt_price_df")) {
				this.mt_price = this.$ls.get("mt_price_df");
				this.is_remeber_price = true;
			}
			//记住赠拍通告佣金
			if (this.task_type == 1 && this.xhs_prom_type != 3 && this.$ls.get("mt_price_zp")) {
				this.mt_price = this.$ls.get("mt_price_zp");
				this.is_remeber_price = true;
			}
			//记住寄拍通告佣金
			if (this.task_type == 2 && this.xhs_prom_type != 3 && this.$ls.get("mt_price_jp")) {
				this.mt_price = this.$ls.get("mt_price_jp");
				this.is_remeber_price = true;
			}

			//获取记住的承诺时间
			if (this.$ls.get('goods_checked_index')) {
				this.picker_time = parseInt(this.$ls.get('goods_checked_index'));
				this.is_remeber_date = true;
			}
		},
		deactivated() {
			if (this.is_remeber_price) {
				if (this.xhs_prom_type == 3) {
					//勾选记住小红书代发通告佣金报价
					this.$ls.set("mt_price_df", this.mt_price);
				} else {
					switch (this.task_type) {
						case 1:
							//勾选记住赠拍通告佣金报价
							this.$ls.set("mt_price_zp", this.mt_price);
							break;
						case 2:
							//勾选记住寄拍通告佣金报价
							this.$ls.set("mt_price_jp", this.mt_price);
							break;
					}
				}
			} else {
				if (this.xhs_prom_type == 3) {
					this.$ls.remove("mt_price_df")
				} else {
					switch (this.task_type) {
						case 1:
							this.$ls.remove("mt_price_zp");
							break;
						case 2:
							this.$ls.remove("mt_price_jp");
							break;
					}
				}
			}

			if (this.is_remeber_date) {
				this.$ls.set("goods_checked_index", this.picker_time)
			} else {
				this.$ls.remove("goods_checked_index")
			}
			this.mt_price = '';
			this.picker_time = 72;
		}
	}
</script>

<style lang="scss" scoped>
	.goodsapply_title {
		width: 100%;
		height: 80px;
		display: flex;
		font-size: 35px;
		align-items: center;
		padding: 0 20px;
		justify-content: center;
		position: relative;

		.back_img {
			position: absolute;
			left: 20px;
			width: 50px;
			height: 50px;
			background-color: rgba(220, 220, 220, 0.5);
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 80%;
				width: 80%;
				margin-left: -10px;
				opacity: 0.6;
			}
		}
	}

	.goodsapply_content {
		padding: 20px 24px;
		background-color: #f2f3f7;
		height: 94vh;
	}

	.goodsapply_pricecontainer {
		border-radius: 20px;
		background-color: white;
		padding: 25px;
		padding-bottom: 40px;
		box-sizing: border-box;
	}

	.goodsapply_typeview {
		display: flex;
		justify-content: space-between;
		/* margin-bottom: 20px; */
		line-height: 40px;
		align-items: flex-end;
	}

	.goodsapply_typetitle {
		height: 40px;
		font-size: 32px;
		color: #262A33;
		line-height: 40px;
	}

	.goodsapply_typesubtitle {
		height: 28px;
		font-size: 20px;
		color: #B8BECC;
		line-height: 28px;
		margin-left: 10px;
	}

	.goodsapply_pricedes {
		font-size: 24px;
		color: #aaa;
	}

	.goodsapply_inputcontainer {
		display: flex;
		line-height: 70px;
	}

	.goodsapply_input_title {
		font-size: 28px;
		color: #262A33;
		display: flex;
	}

	.goodsapply_input_title .cuIcon-question {
		margin-left: 5px;
	}

	.goodsapply_input_content {
		flex: 1;
		line-height: 70px;
		border: 2px solid #EBECF0;
		border-radius: 35px;
		display: flex;
		align-items: center;
		margin: 0 20px;
		padding: 0 30px;
	}

	.goodsapply_input {
		flex: 1;

		.van-field {
			height: 100%;
		}

		::v-deep .van-field__body {
			height: 100%;

			input {
				height: 100%;
				font-size: 30px;

				&::placeholder {
					color: #B8BECC;
					font-size: 24px;
				}
			}
		}

		.van-popup {
			::v-deep .van-picker__toolbar {
				height: 60px;

				button {
					font-size: 30px;
				}
			}

			::v-deep .van-picker__columns {
				.van-picker-column__item {
					font-size: 30px;
				}
			}
		}
	}

	.goodsapply_bottomtitle {
		font-size: 24px;
		color: #aaa;
		line-height: 36px;
		padding: 10px 0 20px;
		overflow: hidden;
	}

	.goodsapply_timecontainer {
		border-radius: 20px;
		background-color: white;
		padding: 25px;
		padding-bottom: 40px;
		box-sizing: border-box;
		margin-top: 20px;
	}

	.goodsapply_timecontent {
		display: flex;
		align-items: center;
	}

	.goodsapply_timetitle {
		height: 33px;
		font-size: 24px;
		color: #262A33;
		line-height: 33px;
		flex-shrink: 0;
	}

	.goodsapply_input_view {
		height: 70px;
		background: #FFFFFF;
		border-radius: 3px;
		border: 1px solid #EBECF0;
		width: 400px;
		margin-left: 10px;
		display: flex;
		align-items: center;
		padding: 0 20px;
		box-sizing: border-box;
	}

	.goodsapply_rember {
		font-size: 22px;
		display: flex;
		align-items: center;

		.van-checkbox {
			height: 100%;

			::v-deep .van-checkbox__icon {
				width: 30px;
				height: 30px;

				.van-icon {
					width: 30px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&.van-checkbox__icon--checked {
					.van-icon {
						background-color: #00C800;
						border-color: #00C800;
					}
				}
			}

			::v-deep .van-checkbox__label {
				font-size: 26px;
			}
		}
	}

	.check-icon {
		font-size: 32px;
		margin-right: 6px;
	}

	.goodsapply_input {
		width: 100%;
		height: 100%;
	}

	.goodsapply_input_subtitle {
		font-size: 28px;
		color: #262A33;
		white-space: nowrap;
	}

	.goodsapply_btn {
		width: 400px;
		height: 80px;
		background: #1A1F29;
		border-radius: 40px;
		font-size: 34px;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		position: fixed;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
	}


	.goodsapply_pickview_time {
		color: #FF704D;
		line-height: 70px;
	}


	.tip-container {
		background-color: #fff;
		border-radius: 20px;
		margin-top: 20px;
		color: #FF704D;
		font-size: 26px;
		padding: 30px 20px;
		line-height: 40px;
		display: flex;

		.van-icon {
			font-size: 30px;
			margin-right: 10px;
		}
	}

	.red-book {
		display: flex;
		justify-content: space-between;
		background-color: #fff;
		border-radius: 20px;
		padding: 0 20px;
		height: 140px;
		align-items: center;
		position: relative;
		margin-top: 20px;
	}

	.red-tag {
		position: absolute;
		left: 0;
		top: 0;
		background-color: red;
		color: #fff;
		font-weight: 700;
		padding: 0 15px;
		line-height: 32px;
		font-size: 20px;
		border-radius: 20px 0 20px 0;
	}

	.red-subtitle {
		font-size: 26px;
		color: #888;
	}

	.rbook-avatar {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		margin-left: 30px;
		display: block;
	}

	.rbook-nick {
		flex: 1;
		margin-left: 15px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-right: 40px;
	}

	.red-book .cuIcon-right {
		font-size: 24px;
		color: #aaa;
	}

	.service-price {
		padding: 30px 20px;
		border-radius: 20px;
		background-color: #fff;
		line-height: 45px;
		margin-top: 20px;
		display: flex;
	}

	.tip-icon {
		color: #FF704D;
		margin-right: 10px;
		font-size: 34px;
	}

	.tip-msg {
		color: #888;
		font-size: 24px;
		line-height: 40px;
		margin-top: 10px;
	}

	::v-deep .van-overlay {
		background-color: rgba(0, 0, 0, .4);
	}

	.vant_popup_box {
		border-radius: 16px;

		.popup_wrapper {
			width: 570px;
			padding: 40px 40px;

			.model-title {
				font-weight: 600;
				font-size: 34px;
				color: #000;
				text-align: center;
			}

			.model_content {
				margin: 40px 0;
				color: #000;
				font-size: 30px;
				line-height: 40px;
			}

			.model-btn-con {
				display: flex;
			}

			.model-btn-con>div {
				width: 220px;
				font-size: 30px;
				line-height: 65px;
				border-radius: 30px;
				border: 1px solid #ddd;
				text-align: center;
				margin: 0 auto;
			}

			.model-btn-item-r {
				background-color: #000000;
				color: #fff;
				border-color: #000000 !important;
			}
		}
	}
</style>
